.nav-bar {
    background:#022c43;
    width: 80px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;
    padding-left: 5px;


    .logo {
        display: block;
        padding: 12px 11px;


        img {
            display: block;
            width: 45px;
            margin-left: 4px;
            height: auto;

        }

    }

    nav {
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        text-align:center;
        position: absolute;
        height: 180px;
        top: 35%;
        margin-top: 10%;
        width: 100%;
       

        a {
            font-size: 27px;
            color: #fbf8be;
            display: block;
            line-height: 57px;
            height: 57px;
            position: relative;
            text-decoration: none;


            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #fbf8be;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 10px;
                // padding: -3px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-in-out;

            }

            &:first-child {
                &::after {
                    content: 'HOME';
                }
            }
        }

        a.about-link {
            &:after {
                content: 'ABOUT';
            }
        }

        a.contact-link {
            &:after {
                content: 'DESIGNS';
            }
        }

        a.active {
            svg {
                color: #ffd700;
            }
        }
    }
    ul{
        position: absolute;
        bottom: 20px;
        width: 100%;
        display:block;
        padding:0 ;
        list-style: none;
        text-align:center;
        margin:0;

        li{
            a{
                padding:7px 0;
                display: block;
                font-size: 20px;
                line-height: 16px;
svg{
    color:#fbf8be;
}
                &:hover svg{
                    color:#ffd700;
                }
            }
        }

    }
}

.hamburger-icon, .close-icon {
    display: none;
  }
  
  
  @media screen and (max-width: 1200px) {
    .nav-bar { 
      background: transparent;
      position: initial;
      height: auto;
      min-height: auto;
  
       ul, nav {
        display: none;
       }
  
       nav {
        width: 100%;
        top: 0;
        left: 0;
        background: #181818;
        height: 100%;
        margin: 0;
        z-index: 2;
        position: fixed;
  
        &.mobile-show {
          display: block;
        }
  
        a {
          display: flex;
          justify-content: center;
          align-items: center;
        }
  
        a:after {
          opacity: 1;
          position: initial;
          width: auto;
          margin-left: 10PX;
        }
  
        a svg {
          opacity: 1 !important;
        }
       }
  
       .hamburger-icon,
       .close-icon {
        
          position: absolute;
          top: 15px;
          right: 15px;
          display: block;
       }
    }
  }