$dark_color: #022c43;

.page {
  // height: 100%;
  width: 100%;
  position: absolute;
  overflow: visible;
  z-index: -3;

}

.container {
  width: 100%;
  will-change: contents;
  height: 100vh;
  // min-height: 100vh;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page,
.contact-page {
  left: 130px;
  overflow: auto;

  .text_zone {
    width: 450px;
  }

  h1 {
    font-size: 76px;
    font-family: 'Coolvetica';
    color: $dark_color;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;
  }

  p {
    font-size: 18px;
    color: #022c43;
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 100%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}