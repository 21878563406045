.container{
    // display:flex ;
    // justify-content: space-evenly;
    margin-left: 10px;
    height: 90%;
    min-height: 100vh;
    position: absolute;
    opacity: 0;
    top: -15%;
    // overflow:scroll;
    // height: 100vh;
}
.contact-page{
    margin-left: 20px;
   
}
.text_zone{
    margin-left: 10px;   
}

.form-zone{
    width: 30%;
    margin-top: 10px;
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
    }
  
    input[type='text'],
    input[type='email'],
    input[type='Subject'] {
      width: 100%;
      border: 2px solid #ffd700 ;
      background: #115173;
      height: 50px;
      font-size: 20px;
      color: #fbf8be;
      padding: 0 20px;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 5px;
    }
  
    textarea {
        border-radius: 10px;
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fbf8be;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }
  
    .flat-button {
        width: 100%;
        color: #022c43;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        background: #115173;
        color: #fbf8be;
        padding: 10px 18px;
        border: 1px solid #022c43;
        text-transform: uppercase;
        margin-top: 25px;
        text-align: center;
        float: left;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;
        border-radius: 10px;
    
        &:hover {
          background: #115173;
          color: #ffd700;
          
        }
      }
    //   #ffd700

  }
  