.home-page {
  
    display: flex;
    flex-direction:row;
    // justify-content: center;
    align-items: center;
    // border: 2px solid black;
    margin-top: -160px;
  
  .text-zone {
    // border: 2px solid blue;
    position: absolute;
    left: 15%;
    top: 70%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  h3 {
    font-size: 30px;
    color: red;
  }

  h1 {
    color: #022c43;
    font-size: 96px;
    line-height: 80px;
    margin-top: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    &::before {

      font-family: 'La Belle Aurore', cursive;
      color: #fbf8be;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &::after {

      font-family: 'La Belle Aurore', cursive;
      color: #fbf8be;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }

  }

  h2 {
    color: #8d8d8d;
    font-weight: 400;
    font-size: 25px;
    font-family: sans-serif;
    letter-spacing: 2.5px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    // color: #022c43;
    background: #022c43;
    color: #fbf8be;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #022c43;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #fbf8be;
      color: #022c43;
      
    }
  }

  .logo-container {
    display: flex;
    width: 350px;
    height: auto;
    margin: 60%;
    margin-top: 80%;
    justify-content: cen;
    /* horizontally center */
    align-items: center;
    /* vertically center */
    // border: 3px solid red;

    .solid-logo {
      width: 350px;
      opacity: 100;
      z-index: 1;
    }

  }
}

@media screen and (max-width: 1200px) {

  .tags,
  .home-page h1:before,
  .home-page h1:after {
    display: none;
    height: 100vh;
  }

  .container {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    overflow-x: hidden;
  }

  .home-page .text-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15%;
    position: initial;
    width: 100%;
    transform: none;
    padding: 10px;
    box-sizing: border-box;
  }

  .home-page .flat-button {
    // float: none;
    display: block;
    margin: 20px auto 0 auto;
    width: 124px;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
.home-page{
  .logo-container {
   flex-direction: column-reverse;
    margin-top:10%;
  }
}

  .solid-logo {

    
  }

  .text-zone {
    text-align: center;
    margin-bottom: 20px;
  }

}

@media screen and (max-width: 400px) {

  .home-page{
    h1{
      font-size: 60px;
      line-height: 55px;
    }
    .logo-container {
     flex-direction: column-reverse;
      margin-top:40%;
      
      .solid-logo{
        width: 100px;
      }
    }
    
  }

}